<template>
	<div class="p-3 liff-reservation-success">

    <div class="liff-reservation-success__title">預約成功！</div>
    <div class="liff-reservation-success__subtitle">您的預約資訊如下，恭候您的光臨！</div>

		<b-card header-tag="header" class="mb-3 liff-reservation-success__card">
			<template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約人資訊</h6>
        </div>
      </template>
			<b-form-group label="姓名" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
         <div class="text-right s-text-sm">{{ user.name }}</div>
			</b-form-group>
			<b-form-group label="電話" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm">
        <div class="text-right s-text-sm">{{ user.mobile_phone }}</div>
			</b-form-group>
		</b-card>

		<b-card header-tag="header" class="mb-3 liff-reservation-success__card">
			<template #header>
				<div class="d-flex justify-content-between align-items-center">
					<h6 class="mb-0">預約資訊</h6>
				</div>
			</template>
			<b-form-group label="項目名稱" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="service.name">
        <div class="text-right s-text-sm">{{ service.name }}</div>

			</b-form-group>
			<b-form-group label="服務人員" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="staff.name">
        <div class="text-right s-text-sm">{{ staff.name }}</div>

			</b-form-group>
			<b-form-group label="預約時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="schedule.start_at">
        <div class="text-right s-text-sm">{{ moment(schedule.start_at).format('YYYY-MM-DD HH:mm') }}</div>
			</b-form-group>
			<b-form-group label="預約結束時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="schedule.end_at">
        <div class="text-right s-text-sm">{{ moment(schedule.end_at).format('YYYY-MM-DD HH:mm') }}</div>
			</b-form-group>
			<b-form-group label="服務地點" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3" v-if="branch.name">
        <div class="text-right s-text-sm">{{ branch.name }}</div>
			</b-form-group>
      <DynamicForm
        ref="dynamicForm"
        :input-subjects="fields"
        v-model="dynamicFormInput"
      >
      </DynamicForm>
		</b-card>

		<div class="d-flex">
			<MemberButton
        button-text="返回"
        type="OA"
      >
      </MemberButton>
		</div>
	</div>
</template>

<script>
  import moment from 'moment'
  import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
  // import SharedButton from '@/components/Page/Liff/Shared/Button.vue';
  import reservationMixin from "@/mixins/liff/reservation";
  import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";

	export default {
  mixins: [reservationMixin],
	components: {
			// SharedButton
			MemberButton,
      DynamicForm
		},
		data() {
			return {
        dynamicFormInput: {},
        hiddenFields: [],
        bookingPreset: {}
			}
		},

    mounted() {
      this.init()
    },

		computed: {
      branchId() {
        return this.$route.query.branch_id
      },
			moment () {
        return moment
      },
      fields() {
				return this.bookingPreset?.config?.fields.filter((field) => {
          return !field?.config?.dashboard_only && !this.hiddenFields.includes(field._id)
				}).map(field => {
          field.id = field?.config?._id || field?._id
          field.disabled = true
          return field
        })
			},

		},

    methods: {
      init() {
        this.fetchPreset()
        this.dynamicFormInput = this.otherData
      },
      async fetchPreset() {
        const data = await this.fetchPresetData(this.$route.params.bookingPresetId, this.branchId);
        this.bookingPreset = data
        if (data?.config?.booking_mapping?.bookings?.start_at) {
          this.hiddenFields.push(data.config.booking_mapping.bookings.start_at)
        }

        if (data?.config?.branch?.field_id) {
          this.hiddenFields.push(data?.config?.branch?.field_id)
        }

        if (data?.config?.service?.collection_id) {
          const serviceField = data?.config?.fields.find((field) => {
            return field.collection_id && field.collection_id == data?.config?.service?.collection_id
          })

          this.hiddenFields.push(serviceField?._id)
        }
      },
      prevStep() {
        this.$router.push({
          name: 'LiffReservationConfirm'
        })
      },
    }
	}

</script>

<style lang="scss" scoped>
.liff-reservation-success {
  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-size: 15px;
    text-align: center;
    margin-bottom: 16px;
  }

	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>
